import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M5.583.5H10.5l-3 4h4l-3 4h4l-9 9 2-6h-3l3-4h-5l5.083-7Z"
    />
  </>
);

export const LighteningBoltIcon = createIcon({
  viewBox: '0 0 13 18',
  vectorComponent
});
