import React from 'react';
import { Path } from 'svgs';
import { createIcon } from '../createIcon';

const vectorComponent = () => (
  <>
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M2.5 8.094c0-2.519 2.018-4.559 4.5-4.559s4.5 2.04 4.5 4.56M7 3.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
    />
    <Path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M4 15.5H.5s2-4.463 2-7.5M11.5 8c0 3.037 2 7.5 2 7.5H10"
    />
    <Path strokeMiterlimit={10} d="M7 17.5a.9.9 0 1 0 0-1.8.9.9 0 0 0 0 1.8Z" />
  </>
);

export const BellIcon = createIcon({ viewBox: '0 0 14 18', vectorComponent });
