import React from 'react';
import { useSiteCountryCode } from '@common/hooks/localisation';
import { useRedirect } from '@common/hooks/useRedirect';
import { useTranslation } from '@common/hooks/useTranslation';
import { Link } from '@components/Link';

export const BackToJobsLink = () => {
  const countryCode = useSiteCountryCode();
  const { redirect } = useRedirect();
  const { t } = useTranslation();

  return (
    <div className="pl-md md:pl-0 lg:pl-0">
      <Link onPress={redirect(`/${countryCode}/`)}>
        {t('postJobSuccessPage.backToJobs')}
      </Link>
    </div>
  );
};
