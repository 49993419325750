import React from 'react';
import { I18nextTrans, useTranslation } from '@common/hooks/useTranslation';
import { useTrackUpgradeAccountButtonClicked } from '@src/joraEmployer/pages/account/hooks';
import { Heading } from '@components/Heading';
import {
  BellIcon,
  CommentIcon,
  LighteningBoltIcon,
  MaxAdIcon
} from '@components/Icon';
import { Link } from '@components/Link';
import { useModal } from '@components/Modal';
import { Text } from '@components/Text';
import { UpgradeModal } from './UpgradeModal';

type Props = {
  emailAddress: string;
  showUpgradeAccountInfo: boolean;
  stepTitle: string;
  source: string;
};

export const WhatHappensNextInfo = ({
  emailAddress,
  source,
  showUpgradeAccountInfo,
  stepTitle
}: Props) => {
  const { t } = useTranslation();
  const { isModalOpen, openModal, closeModal } = useModal();
  const trackUpgradeAccountButtonClicked = useTrackUpgradeAccountButtonClicked({
    source
  });

  const onUpgradeClicked = () => {
    openModal();
    trackUpgradeAccountButtonClicked();
  };

  return (
    <div className="flex flex-col pt-[24px] p-lg bg-background-neutral">
      <div className="flex flex-col gap-md">
        <Heading level="h2">
          {t('postJobSuccessPage.description.whatHappensNext')}
        </Heading>

        <div className="flex flex-row gap-md items-start">
          <div className="px-[6px] pt-[4px]">
            <BellIcon variant="outline" tone="brand" height="medium" />
          </div>
          <Text>
            {t('postJobSuccessPage.description.applicantAlerts', {
              emailAddress
            })}
          </Text>
        </div>

        <div className="flex flex-row gap-md items-start">
          <div className="pt-[4px]">
            <MaxAdIcon variant="outline" tone="brand" height="medium" />
          </div>
          <Text>
            {t('postJobSuccessPage.description.reviewApplicantsInDashboard')}
          </Text>
        </div>

        <div className="flex flex-row gap-md items-start">
          <div className="px-[4px] pt-[4px]">
            <CommentIcon variant="outline" tone="brand" height="medium" />
          </div>
          <Text>
            {t('postJobSuccessPage.description.connectWithApplicants')}
          </Text>
        </div>

        {showUpgradeAccountInfo && (
          <div className="flex flex-row gap-md items-start">
            <div className="px-[6px] pt-[6px]">
              <LighteningBoltIcon
                variant="outline"
                tone="brand"
                height="medium"
              />
            </div>
            <I18nextTrans
              extraTopMargin="small"
              parent={Text}
              i18nKey="postJobSuccessPage.description.upgradeYourAccount"
              components={[
                <Link onPress={onUpgradeClicked} weight="regular" />
              ]}
            />
          </div>
        )}
      </div>

      <UpgradeModal
        stepTitle={stepTitle}
        source={source}
        modalOptions={{
          isModalOpen,
          openModal,
          closeModal
        }}
      />
    </div>
  );
};
