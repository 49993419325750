import React from 'react';
import { UpgradeAccountModal } from '@common/components/Account/components';
import { useGetHomeLink } from '@common/hooks/appPageLinks';
import { useBrandNameText } from '@common/hooks/localisation';
import { useRequestUpgradeAccountCallback } from '@common/hooks/orgs/useRequestUpgradeAccountCallback';
import { useRedirect } from '@common/hooks/useRedirect';
import { useTrackContactMeButtonClicked } from '@src/joraEmployer/pages/account/hooks/useTrackContactMeClicked/useTrackContactMeClicked';
import { getUser } from '@store/auth';
import { useAppSelector } from '@store/hooks';

type Props = {
  stepTitle: string;
  source: string;
  modalOptions: {
    isModalOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
  };
};

export const UpgradeModal = ({
  stepTitle,
  source,
  modalOptions: { isModalOpen, closeModal }
}: Props) => {
  const { redirect } = useRedirect();
  const { currentUser } = useAppSelector(getUser);

  const trackContactMeClicked = useTrackContactMeButtonClicked();
  const { requestUpgradeAccountCallback } = useRequestUpgradeAccountCallback();
  const brandNameText = useBrandNameText();
  const homePath = useGetHomeLink();

  const handleRequstUpgradeSubmit = () => {
    requestUpgradeAccountCallback({
      page: `${stepTitle} | ${brandNameText}`,
      source
    });
    trackContactMeClicked();
  };

  const handleCloseModalWhenComplete = () => {
    redirect(homePath)();
  };

  return (
    <UpgradeAccountModal
      mobile={currentUser.mobile}
      email={currentUser.email}
      visible={isModalOpen}
      onRequestClose={closeModal}
      onRequestUpgradeSubmit={handleRequstUpgradeSubmit}
      onCloseModalWhenComplete={handleCloseModalWhenComplete}
    />
  );
};
